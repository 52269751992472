<template>
  <ion-page>
    <TheHeaderLarge :subseccion-no-trans="day"/>
    <ion-content fullscreen>
      <AppInfoGtm/>
      <TheList :items-list="presentationsList">
        <template v-slot:action="{ item }">
          <template v-if="item.extraData.selected">
            <ion-button shape="round" color="danger" @click="$event.stopPropagation(); removePresentation(item.id)">
              quitar
              <ion-icon style="margin-left: 0.5em" :icon="trash"></ion-icon>
            </ion-button>
          </template>
          <template v-else>
            <ion-button shape="round" class="ion-margin-vertical" color="light"
                        @click="$event.stopPropagation(); addPresentation(item)">
              Agendar
              <ion-icon style="margin-left: 0.5em" :icon="today"></ion-icon>
            </ion-button>
          </template>
        </template>
      </TheList>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonButton,
  IonContent, IonIcon,
  IonPage,
} from '@ionic/vue';
import { computed, defineComponent, onMounted, ref } from 'vue';
import TheHeaderLarge from "@/components/TheHeaderLarge.vue";
import TheList from "@/components/List/TheList.vue";
import { useRoute } from "vue-router";
import UsePushLink from "@/composables/usePushLink";
import { programService } from "@/services/program.service";
import { trash, today } from 'ionicons/icons';
import useMyAgenda from "@/composables/useMyAgenda";
import { Presentation } from "@/interfaces/Program/Presentation";
import AppInfoGtm from "@/components/GTM/AppInfoGtm.vue";

export default defineComponent({
  name: 'Presentations',
  components: {
    AppInfoGtm,
    TheList,
    TheHeaderLarge,
    IonContent,
    IonPage,
    IonButton,
    IonIcon,
  },
  setup() {
    const route = useRoute()
    const eventId: string = route.params.eventId.toString()
    const dayId: string = route.params.dayId.toString()
    const { getUrl } = UsePushLink()
    const day = ref('')
    const presentations = ref<Presentation[]>([])
    const { addPresentation, removePresentation, hasPresentation } = useMyAgenda(eventId)

    const presentationsList = computed(() => {

      if ( !presentations.value.length ) {
        return []
      }

      return presentations.value.map((presentation) => {
        return {
          id: presentation.id,
          title: presentation.titulo,
          hour: presentation.hora,
          category: presentation.keynote ? 'Keynote' : undefined,
          important: (presentation.keynote),
          link: getUrl('Presentation', { eventId: eventId, presentationId: presentation.id.toString() }),
          extraData: {
            day: day.value,
            selected: hasPresentation(presentation.id),
          }
        }
      })
    })


    onMounted(async () => {
      const presentationsData = await programService.presentations(eventId, dayId)
      day.value = presentationsData.dia + ' ' + presentationsData.fecha
      presentations.value = presentationsData.presentaciones
    })

    return {
      presentations,
      presentationsList,
      day,
      addPresentation,
      removePresentation,
      trash,
      today
    }
  }
});
</script>

<style scoped>
#container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;

  color: #8c8c8c;

  margin: 0;
}

#container a {
  text-decoration: none;
}
</style>